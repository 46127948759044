@font-face {
  font-family: 'ForwardExtra';
  src: url('fonts/forwardextra_light.otf');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.chat-bottom {
  display: none;
}

.rcw-conversation-container {
  padding: 20px;
  background: #005234;
  font-family: 'ForwardExtra';
}

.rcw-conversation-container .rcw-header {
  background-color: #005234 !important;
  padding: 0px 0px 25px 85px !important;
  text-align: left !important;
  background-image: url(chat-logo.png);
  background-size: 75px;
  background-repeat: no-repeat;
  background-position: center left;
}

.rcw-conversation-container .rcw-header span {
  font-size: 0.8em;
  font-style: italic;
}

.rcw-launcher {
  background-color: #005234 !important;
}

.rcw-conversation-container .rcw-title {
  font-weight: 100;
  font-size: 1.2em !important;
}

.rcw-messages-container {
  background-color: #81a99b !important;
}

.rcw-sender {
  background-color: #4d897c !important;
}

.rcw-send {
  background: none !important;
}

.rcw-message {
  margin-bottom: 20px;
}

.rcw-new-message {
  width: calc(86% - 40px) !important;
}

.rcw-client .rcw-message-text {
  max-width: 195px !important;
}

.rcw-client {
  flex-direction: row-reverse !important;
  justify-content: space-around;
}

.rcw-response {
  flex-direction: row !important;
  justify-content: space-around;
}

.rcw-client .rcw-message-text {
  background-color: #fff !important;
  color: #005234;
}

.rcw-client .rcw-timestamp,
.rcw-response .rcw-timestamp{
  align-self: center !important;
  margin: 0 15px;
  font-size: 1em;
  color: #005234;
}

#messages::-webkit-scrollbar {
  width: 10px;
  background-color: #81a99b;
}

#messages::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #4d897c;
}

#messages::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #81a99b;
}